import { FC, MouseEvent, useMemo } from 'react';
import { Link, Typography } from 'react-md';
import { IEpisode } from '../../../pages/Home';
import styles from './styles.module.scss';

interface HeaderProps {
  date: IEpisode['date'];
  episode: IEpisode['episode'];
  title: IEpisode['title'];
  url: IEpisode['url'];
}

const containsPipe = (title: string) => title.includes(' | ');

const Header: FC<HeaderProps> = ({ date, episode, title, url }) => {
  const [numberTitle, description] = useMemo(() => {
    // More recent episodes have a different title structure.
    // Perhaps instead the data itself to be cleaned up to match this new structure?
    // However, it may change again in the future!
    if (containsPipe(title)) {
      return title.split(' | ').reverse();
    }

    return title.split(' - ');
  }, [title]);
  
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <header className={styles.header}>
      <Link
        className={styles.imgLink}
        flexCentered
        href={url}
        onClick={handleClick}
        target="_blank"
      >
        <img
          alt={`Thumbnail episode ${episode}`}
          className={styles.img}
          width="168"
          src={numberTitle.includes('Live') ? 'episode-thumbs/live.png' : `episode-thumbs/episode-${episode}.png`}
        />
      </Link>

      <div className={styles.title}>
        <Typography type="overline">{numberTitle}</Typography>
        <Typography className={styles.bold} type="headline-5">
          {description}
        </Typography>
        <Typography type="overline">{date}</Typography>
      </div>
    </header>
  );
};

export default Header;
