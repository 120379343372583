import React, { FC, useEffect, useMemo, useState } from 'react';
import { Checkbox, Link, Typography } from 'react-md';
import Episode from '../../components/Episode';
import styles from './styles.module.scss';

export interface IEpisode {
  date: string;
  episode: string;
  id: string;
  links: {
    href: string;
    text: string;
  }[];
  title: string;
  url: string;
}

const Home: FC = () => {
  const [data, setData] = useState<IEpisode[]>([]);
  const [expandAll, setExpandAll] = useState(false);
  const [isSwitchFlipHidden, setIsSwitchFlipHidden] = useState(false);
  const [isReverse, setIsReverse] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const fetchData = () =>
        fetch('data.json', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

      const response = await (await fetchData()).json();
      setData(response);
    };

    getData();
  }, []);

  const episodeData = useMemo(() => {
    const theData = [...data];

    return isReverse ? theData.reverse() : theData;
  }, [data, isReverse]);

  return (
    <main className={styles.container}>
      <header className={styles.header}>
        <Typography className={styles.title} type="headline-6">
          "Links We Talked About", From{' '}
          <Link href="https://thenineclub.com/blogs/experience" target="_blank">
            The Nine Club Experience
          </Link>
        </Typography>
        <div className={styles.settings}>
          <Checkbox
            id="chrono"
            name="chrono"
            label="Chronological Order"
            onChange={() => setIsReverse(!isReverse)}
          />

          <Checkbox
            id="expand"
            name="expand"
            label="Expand All"
            onChange={() => setExpandAll(!expandAll)}
          />

          <Checkbox
            id="switchFlip"
            name="switchFlip"
            label="Hide Switchflip Switch Manny links"
            onChange={() => setIsSwitchFlipHidden(!isSwitchFlipHidden)}
          />
        </div>
      </header>

      <article className={styles.articleContainer}>
        {episodeData.map(dataItem => {
          return (
            <Episode
              data={dataItem}
              isExpanded={expandAll}
              isSwitchFlipHidden={isSwitchFlipHidden}
              key={dataItem.id}
            />
          );
        })}
      </article>
    </main>
  );
};

export default Home;
