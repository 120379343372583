import { decode } from 'he';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ExpansionPanel, Link } from 'react-md';
import { IEpisode } from '../../pages/Home';
import Header from './Header';
import styles from './styles.module.scss';

interface EpisodeProps {
  data: IEpisode;
  isExpanded: boolean;
  isSwitchFlipHidden: boolean;
}

const Episode: FC<EpisodeProps> = ({
  data: { date, episode, id, title, links, url },
  isExpanded,
  isSwitchFlipHidden,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const episodeLinks = useMemo(() => {
    return isSwitchFlipHidden
      ? links.filter(
          link => link.href !== 'https://www.youtube.com/watch?v=EejsdKHgwsU'
        )
      : links;
  }, [isSwitchFlipHidden, links]);

  useEffect(() => {
    setExpanded(isExpanded);

    return () => {
      setExpanded(false);
    };
  }, [isExpanded]);

  return (
    <ExpansionPanel
      className={styles.panel}
      expanded={expanded}
      header={<Header date={date} episode={episode} title={title} url={url} />}
      id={`episode-${id}`}
      onExpandClick={() => setExpanded(!expanded)}
    >
      <ul className={styles.list}>
        {episodeLinks.map(link => {
          return (
            <li key={`${link.text}${link.href}${id}`}>
              <Link href={link.href} target="_blank">
                {decode(link.text)}
              </Link>
            </li>
          );
        })}
      </ul>
    </ExpansionPanel>
  );
};

export default Episode;
